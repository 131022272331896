import React from "react"

import AboutCTABody from "./about_cta_body"
import AboutCTAImage from "./about_cta_image"

const AboutCTABlock = ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <section className="feature-area section-min-padding">
      <div className="container">
        <div className="row">
          <div
            className={
              data.orientation === 2
                ? "col-lg-12 col-xs-12 d-flex flex-column-reverse flex-xs-column flex-lg-row align-items-center"
                : "col-lg-12 col-xs-12 align-items-center feature-sub-area"
            }
          >
            {data.orientation === 2 && (
              <>
                <AboutCTAImage image={data.image} />
                <AboutCTABody
                  title={data.title}
                  description={data.description}
                />
              </>
            )}
            {data.orientation === 1 && (
              <>
                <AboutCTABody
                  title={data.title}
                  description={data.description}
                />
                <AboutCTAImage image={data.image} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutCTABlock
