import React from "react"
import PropTypes from "prop-types"

const AboutCTABody = ({ title, description }) => {
  return (
    <div className="col-lg-6 col-xs-12">
      <div>
        <h3>{title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </div>
  )
}

AboutCTABody.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default AboutCTABody
