import React from "react"

const AboutCTAImage = ({ image }) => {
  return (
    <div className="col-lg-6 col-xs-12">
      <div className="feature-img">
        <div className="zoom">
          <img className="img-fluid" src={image.src} alt={image.title} />
        </div>
      </div>
    </div>
  )
}

export default AboutCTAImage
