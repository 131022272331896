import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"

import CTABlock from "../components/blocks/cta_block"
import AboutCTABlock from "../components/blocks/about_cta_block"

const AboutPage = ({ data }) => {
  return (
    <>
      <Layout
        isContent={true}
        subMenu={data.wagtail.page.children}
        button={data.wagtail.page.button}
        parent={"about"}
        siteTitle={"About"}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>{data.wagtail.page.title}</h1>
                <div
                  className="c-title-content"
                  dangerouslySetInnerHTML={{
                    __html: data.wagtail.page.bodySafe,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        {data.wagtail.page.initiatives.map((item, idx) => {
          return <AboutCTABlock data={item} key={`${idx}-about-cta-block`} />
        })}
        <CTABlock data={data.wagtail.page.fullContent[0]} />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    wagtail {
      page(slug: "about") {
        ... on AboutPage {
          id
          title
          descriptionSimple
          body
          bodySafe
          children {
            title
            slug
            ... on SimplePage {
              live
              isProtected
            }
            ... on AboutProfilePage {
              live
              isProtected
            }
          }
          button {
            name
            text
            url
          }
          initiatives {
            ... on CTAAboutBlock {
              title
              description
              image {
                src
                title
              }
              orientation
            }
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default AboutPage
